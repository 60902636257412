.contain {
  max-width: var(--contain);
  margin-inline: auto;
}

.contain--sm {
  max-width: var(--containSm);
}

.contain--md {
  max-width: var(--containMd);
}

.contain-padding {
  padding-inline: var(--containPadding);
}
