.grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(var(--minColWidth), 1fr));
  grid-column-gap: 6rem;

  &__content {
    padding: 2rem;

    p {
      margin: 0;
    }
  }
}

.grid--center {
  align-items: center;
}
