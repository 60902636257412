.nav {
  position: relative;
  z-index: 2;
  display: flex;
  justify-content: space-between;
  justify-items: center;
  width: 100%;
  padding-block: 0.5rem;
  background-color: var(--colorWhite);

  &__menu {
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    height: 0;
    overflow: hidden;
    background-color: var(--colorPrimary);
    text-align: center;

    &.active {
      height: auto;
    }

    li {
      a {
        font-family: var(--fontDisplay);
        display: block;
        padding: 1rem var(--containPadding);
        color: var(--colorWhite);
        background-color: var(--colorPrimary);

        &.active {
          color: var(--colorButtonDarker);
        }
      }
    }
  }
}

.nav__toggle {
  display: block;
  position: absolute;
  right: 0;
  width: 50px;
  height: 50px;
  cursor: pointer;
}

span.icon-bar {
  position: absolute;
  right: 12px;
  display: block;
  width: 26px;
  height: 2px;
  background-color: var(--colorPrimary);
  transition-duration: 0.3s;
}

.icon-bar:nth-child(1) {
  top: 17px;
}

.icon-bar:nth-child(2) {
  top: 24px;
}

.icon-bar:nth-child(3) {
  top: 31px;
}

.nav__toggle.active .icon-bar:nth-child(1) {
  top: 24px;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  transform: rotate(45deg);
}

.nav__toggle.active .icon-bar:nth-child(2) {
  width: 0;
}

.nav__toggle.active .icon-bar:nth-child(3) {
  top: 24px;
  transform: rotate(-45deg);
}

.nav-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--colorPrimary);
  z-index: 1;
  opacity: 0;
  visibility: hidden;
}

.nav-overlay.active {
  opacity: 1;
  visibility: visible;
}

@media (min-width: 1024px) {
  .nav {
    padding-block: 1rem;

    &__menu {
      display: flex;
      align-items: flex-end;
      position: relative;
      top: 0;
      width: auto;
      height: auto;
      background-color: transparent;
      color: var(--colorText);

      li {
        &:first-child {
          margin-inline-start: 0;

          a {
            padding-block-start: 1rem;
          }
        }

        a {
          padding: 1rem 1.5rem;
          color: var(--colorText);
          background-color: transparent;

          &.active {
            color: var(--colorButtonDark);
          }
        }
      }
    }
  }

  .nav__toggle {
    display: none;
  }

  .nav-overlay.active {
    opacity: 0;
    visibility: hidden;
  }
}

@media (hover: hover) {
  .nav {
    &__menu {
      li {
        a {
          transition: all 0.2s ease-in-out;
          &:hover {
            background-color: var(--colorButtonDarker);
            color: var(--colorWhite);
          }
        }
      }
    }
  }

  @media (min-width: 1024px) {
    .nav {
      &__menu {
        li {
          a {
            transition: color 0.2s ease-in-out;
            &:hover {
              color: var(--colorButtonDark);
              background-color: transparent;
            }
          }
        }
      }
    }
  }
}
