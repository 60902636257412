:root {
  --contain: min(1600px, 100%);
  --containSm: min(700px, 100%);
  --containMd: min(1000px, 100%);
  --containPadding: 5%;
  --minColWidth: 25rem;
  --fontText: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans,
    Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  --fontDisplay: "Domine", serif;
  --colorWhite: hsl(0, 0%, 100%);
  --colorText: hsl(43, 56%, 22%);
  --colorPrimary: hsl(180, 60%, 50%);
  --colorBodyBackground: hsl(0, 0%, 95%);
  --colorButton: var(--colorPrimary);
  --colorButtonDark: hsl(180, 60%, 40%);
  --colorButtonDarker: hsl(180, 60%, 20%);
  --sectionSpacing: clamp(4rem, 8vw, 8rem);
  --textLineHeight: 1.5;
  --smFontSize: 12px;
  --baseFontSize: clamp(16px, 2vw, 18px);
  --lgFontSize: clamp(18px, 2vw, 26px);
  --xlFontSize: clamp(30px, 5vw, 40px);
  --headingLineHeight: 1.1;
  --fontWeightBold: 600;
}
