// Create buttonColor variable and redeclare it in secondary
.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.25em 0.75em;
  min-width: 20ch;
  min-height: 48px;
  text-align: center;
  line-height: 1.1;
  font-family: var(--fontDisplay);
  background-color: var(--colorButton);
  transition: 220ms all ease-in-out;
  color: var(--colorWhite);
  border-radius: 2px;

  &:active {
    background-color: var(--colorButtonDark);
  }

  &:focus {
    outline-style: solid;
    outline-color: transparent;
    box-shadow: 0 0 0 4px var(--colorButtonDarker);
  }
}

.btn--secondary {
  color: var(--colorPrimary);
  background-color: var(--colorWhite);
  border: 1px solid currentColor;

  &:active {
    background-color: var(--colorPrimary);
    color: var(--colorWhite);
  }

  &:focus {
    outline-style: solid;
    outline-color: transparent;
    box-shadow: 0 0 0 4px var(--colorButtonDark);
  }
}

.btn-group {
  margin-block-start: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;

  .btn {
    display: flex;
    width: 100%;
  }
}

@media (min-width: 600px) {
  .btn-group {
    display: flex;
    flex-direction: row;

    .btn {
      width: auto;
    }
  }
}

@media (hover: hover) {
  .btn {
    &:hover {
      background-color: var(--colorButtonDark);
    }
  }

  .btn--secondary {
    &:hover {
      background-color: var(--colorPrimary);
      color: var(--colorWhite);
    }
  }
}
