.image {
  aspect-ratio: 32 / 9;
  background-color: var(--colorBodyBackground);
  overflow: hidden;

  &--first {
    display: none;
  }
}

@media (min-width: 768px) {
  .image {
    &--first {
      display: block;
    }
  }
}
