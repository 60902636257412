@use "styles/reset";
@use "styles/typography";
@use "styles/swiper";
@use "styles/variables";
@use "styles/contain";
@use "styles/buttons";
@use "styles/header";
@use "styles/logo";
@use "styles/nav";
@use "styles/hero";
@use "styles/footer";
@use "styles/grid";
@use "styles/image";
@use "styles/section";
@use "styles/prices";
@import url("https://fonts.googleapis.com/css2?family=Domine&display=swap");

html {
  /* CSS custom property for the polyfill */
  --scroll-behavior: smooth;
  background-color: var(--colorBodyBackground);
  scroll-behavior: smooth;
}

body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: var(--colorWhite);
  color: var(--colorText);
  font-family: var(--fontText);
  font-size: 1rem;
  line-height: var(--textLineHeight);

  &.locked {
    overflow: hidden;
    max-height: 100vh;
  }
}

.products {
  h2,
  p {
    text-align: start;
  }

  &__grid {
    display: grid;
    align-items: center;
    grid-template-areas:
      "slider"
      "content";
  }

  &__content {
    grid-area: content;
    padding: var(--sectionSpacing) var(--containPadding);

    p {
      margin-block-start: 2rem;
      font-size: 1rem;
    }

    address {
      margin-block-end: 2rem;

      a:first-of-type {
        display: inline-block;
        margin-block-start: 1rem;
      }
    }

    dt,
    strong {
      font-weight: var(--fontWeightBold);
    }
  }

  &__slider {
    grid-area: slider;
    width: 100%;
    aspect-ratio: 21 / 9;
  }
}

@media (min-width: 768px) {
  .products {
    &__grid {
      grid-template-areas: "content slider";
      grid-template-columns: repeat(2, 1fr);
    }

    &__slider {
      aspect-ratio: 1 / 1;
    }
  }
}

@media (min-width: 1024px) {
  .products {
    &__content {
      padding-inline-end: 6rem;
      padding-inline-start: 6rem;
    }
  }
}

@media (hover: hover) {
  address a {
    transition: color 0.2s ease-in-out;

    &:hover {
      color: var(--colorPrimary);
    }
  }
}

#map {
  height: 100%;
  width: 100%;
}
