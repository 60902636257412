.prices {
  padding-block: var(--sectionSpacing);
  text-align: center;

  &__table {
    margin-block-start: 2rem;

    dl {
      display: grid;
      grid-template-columns: 1fr auto;
      text-align: start;
      line-height: 2;
    }

    dt {
      font-weight: var(--fontWeightBold);
    }

    dd {
      text-align: end;
    }
  }
}

@media (min-width: 760px) {
  .prices {
    &__table {
      display: grid;
      grid-template-columns: repeat(2, 1fr);

      dl {
        line-height: 1.5;
      }

      dl:first-child {
        padding-inline-end: 2rem;
        border-right: 1px solid var(--colorText);
      }

      dl:last-child {
        padding-inline-start: 2rem;
      }
    }
  }
}
