p,
li,
h1,
h2,
h3,
h4 {
  // Help prevent overflow of long words/names/URLs
  word-break: break-word;

  // Optional, not supported for all languages:
  hyphens: auto;
}

h1,
h2 {
  color: var(--colorPrimary);
  font-family: var(--fontDisplay);
  font-weight: 700;
  line-height: 1;
  font-size: var(--xlFontSize);
}

p,
span {
  font-size: var(--baseFontSize);
}
