.footer {
  margin-block-start: auto;
  padding-block: 1rem;
  background-color: var(--colorText);
  color: var(--colorWhite);
  text-align: center;
  font-size: var(--smFontSize);

  small {
    opacity: 0.5;
  }
}

@media (min-width: 1024px) {
  .footer {
    padding-block: 2rem;
  }
}
