.section {
  padding-block: var(--sectionSpacing);
  text-align: center;

  p {
    max-width: 60ch;
    margin: 1rem auto 2rem;

    &:last-child {
      margin-block-end: 0;
    }
  }
}
