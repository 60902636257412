.hero {
  position: relative;
  color: var(--colorWhite);
  background-color: var(--colorPrimary);
  overflow: hidden;

  &__media {
    aspect-ratio: 4 / 3;
  }

  &__banner {
    padding-block: 1rem;
    background-color: var(--colorPrimary);
    font-family: var(--fontDisplay);
    text-align: center;
    color: var(--colorWhite);

    a {
      white-space: nowrap;
      text-decoration: underline;
    }
  }

  .swiper {
    width: 100%;
    height: 100%;
  }
}

@media (min-width: 1024px) {
  .hero {
    &__media {
      aspect-ratio: 21 / 9;
    }

    &__banner {
      padding-block: 2rem;

      p {
        font-size: var(--lgFontSize);
      }
    }
  }
}

@media (hover: hover) {
  .hero__banner a {
    transition: color 0.2s ease-in-out;

    &:hover {
      color: var(--colorButtonDarker);
    }
  }
}
