.logo {
  display: block;
  height: 44px;

  img {
    height: 100%;
    width: auto;
  }
}

@media (min-width: 1024px) {
  .logo {
    height: 72px;
  }
}
